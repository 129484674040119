<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    commonList,
  } from "@/api/web/common.js";


  /**
   * 公共信息
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "公共信息 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query:{
          keyword: "",
          bigTypeId: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        checked:true,
        list:[],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0

      };
    },

    mounted() {
      this.getList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询公共信息列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        commonList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 前往编辑公共信息 */
      goToEditCommon(commonId) {
        this.$router.push({
          path: '/admin/commonPublish',
          name: 'commonPublish',
          params: {
            commonId: commonId
          }
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">

            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <input placeholder="输入文件名或标题" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15" maxlength="50"/>
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
              </form>
            </div>

            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%"> 序号</th>
                    <th style="width:10%">json文件名称</th>
                    <th >标题</th>
                    <th style="width:7%">访问</th>
                    <th style="width:7%">编辑人</th>
                    <th style="width:7%">编辑时间</th>
                    <th style="width:4%">操作</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(obj, index) in list" :key="index">
                  <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.jsonpName}}</td>
                    <td>{{obj.commonName}}</td>
                    <td>{{obj.viewCount}} </td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.modifyTime != null ? obj.modifyTime.substring(0,10) : ""}}</td>
                    <td class="tab-icon">
                      <i class="iconfont icon-edit-two" title="修改" @click="goToEditCommon(obj.commonId)"></i></td>
                </tr>

                </tbody>


              </table>

            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
