import { getRequest, postRequest, downloadRequest, patchRequest, deleteRequest } from "@/libs/axios";

// ------------------------公共信息 管理-----------------------
// 公共信息列表
export function commonList(params) {
    return getRequest("/yethan/web/common/listPage", params);
}
// 公共信息列表-导出
export function commonListExport(params) {
    return downloadRequest("/yethan/web/common/listPage/exportExcel", params);
}
// 公共信息详情
export function commonDetail(commonId) {
    return getRequest("/yethan/web/common/"+commonId);
}
// 公共信息保存
export function saveCommon(params) {
    return postRequest("/yethan/web/common", params);
}
